exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-grand-menu-tsx": () => import("./../../../src/pages/grandMenu.tsx" /* webpackChunkName: "component---src-pages-grand-menu-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quiz-10-tsx": () => import("./../../../src/pages/quiz/10.tsx" /* webpackChunkName: "component---src-pages-quiz-10-tsx" */),
  "component---src-pages-quiz-15-tsx": () => import("./../../../src/pages/quiz/15.tsx" /* webpackChunkName: "component---src-pages-quiz-15-tsx" */),
  "component---src-pages-quiz-7-tsx": () => import("./../../../src/pages/quiz/7.tsx" /* webpackChunkName: "component---src-pages-quiz-7-tsx" */),
  "component---src-pages-quiz-8-tsx": () => import("./../../../src/pages/quiz/8.tsx" /* webpackChunkName: "component---src-pages-quiz-8-tsx" */),
  "component---src-pages-quiz-content-id-tsx": () => import("./../../../src/pages/quiz/[contentId].tsx" /* webpackChunkName: "component---src-pages-quiz-content-id-tsx" */),
  "component---src-pages-quiz-index-tsx": () => import("./../../../src/pages/quiz/index.tsx" /* webpackChunkName: "component---src-pages-quiz-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-video-1-tsx": () => import("./../../../src/pages/video/1.tsx" /* webpackChunkName: "component---src-pages-video-1-tsx" */),
  "component---src-pages-video-10-tsx": () => import("./../../../src/pages/video/10.tsx" /* webpackChunkName: "component---src-pages-video-10-tsx" */),
  "component---src-pages-video-11-tsx": () => import("./../../../src/pages/video/11.tsx" /* webpackChunkName: "component---src-pages-video-11-tsx" */),
  "component---src-pages-video-12-tsx": () => import("./../../../src/pages/video/12.tsx" /* webpackChunkName: "component---src-pages-video-12-tsx" */),
  "component---src-pages-video-13-tsx": () => import("./../../../src/pages/video/13.tsx" /* webpackChunkName: "component---src-pages-video-13-tsx" */),
  "component---src-pages-video-14-tsx": () => import("./../../../src/pages/video/14.tsx" /* webpackChunkName: "component---src-pages-video-14-tsx" */),
  "component---src-pages-video-2-tsx": () => import("./../../../src/pages/video/2.tsx" /* webpackChunkName: "component---src-pages-video-2-tsx" */),
  "component---src-pages-video-3-tsx": () => import("./../../../src/pages/video/3.tsx" /* webpackChunkName: "component---src-pages-video-3-tsx" */),
  "component---src-pages-video-4-tsx": () => import("./../../../src/pages/video/4.tsx" /* webpackChunkName: "component---src-pages-video-4-tsx" */),
  "component---src-pages-video-5-tsx": () => import("./../../../src/pages/video/5.tsx" /* webpackChunkName: "component---src-pages-video-5-tsx" */),
  "component---src-pages-video-6-tsx": () => import("./../../../src/pages/video/6.tsx" /* webpackChunkName: "component---src-pages-video-6-tsx" */),
  "component---src-pages-video-7-tsx": () => import("./../../../src/pages/video/7.tsx" /* webpackChunkName: "component---src-pages-video-7-tsx" */),
  "component---src-pages-video-8-tsx": () => import("./../../../src/pages/video/8.tsx" /* webpackChunkName: "component---src-pages-video-8-tsx" */),
  "component---src-pages-video-9-tsx": () => import("./../../../src/pages/video/9.tsx" /* webpackChunkName: "component---src-pages-video-9-tsx" */),
  "component---src-pages-video-index-tsx": () => import("./../../../src/pages/video/index.tsx" /* webpackChunkName: "component---src-pages-video-index-tsx" */)
}

